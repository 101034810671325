<template>
  <v-card>
    <v-toolbar
      flat
      color="indigo darken-4"
      dark
    >
      <v-toolbar-title>Notificaciones</v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical>
      <v-tab v-for="(type) in notification_types" :key="`tab_${type.code}`">
        <v-icon left size="medium">
          mdi-clock-alert
        </v-icon>{{type.name}}
      </v-tab>
      <v-tab-item v-for="(type) in notification_types" :key="`tab_item_${type.code}`">
        <v-card class="mx-auto">
          <v-card-text>
            <v-list-item v-for="(notification) in getNotificationsByType(type.code)" :key="notification.id">
              <v-list-item-content @click="markAsRead(notification.id)">
                <v-list-item-subtitle>{{notification.data}}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <small>{{notification.created_at | formatAgo}}</small>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-badge dot overlap v-if="notification.pivot.read_at === null"></v-badge>
            </v-list-item>
            <v-list-item v-if="!getNotificationsByType(type.code).length">
              <v-list-item-content>
                <v-list-item-subtitle>No hay información para mostrar</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>

import axios from "axios";
import { basePath, getHeader } from "../config";
import {notificationTypes} from "../enums/notificationTypes";
export default {
  data: () => ({
  }),
  computed: {
    notifications (){
      return this.$store.state.notifications;
    },
    notification_types (){
      return notificationTypes
    }
  },
  methods: {
    markAsRead(id){
      let index = this.notifications.findIndex(x => x.id === id);
      if(this.notifications[index].pivot.read_at === null){
        axios.get(`${basePath}notifications/mark-as-read/${id}`, {headers: getHeader()});
      }
    },
    getNotificationsByType(type){
      return this.notifications.filter(x => x.type === type);
    }
  },
};
</script>
